import { presenceChannel } from "../index";
import { getRaiPos } from "../../setup";

const raiPosAPI = getRaiPos({ window });

// This is the module that wires up event listeners
// for POS client web socket events sent from other devices, particularly
// tablets.
//
// In order to handle a client event, a function handler should
// be added to the ACTIONS object. The name of the function should
// correspond to the action that is being sent.
//
// Example:
//  - tablet sends client event with an action of `printTestPage`
//  - ACTIONS should have a function with a name of `printTestPage` that
//  handles the request, then returns an object with a `result` key and/or an `error`
//  key. If an `error` key is returned, it is assumed the operation was not
// successful.

// Bind to pos-<posName>-message events
export const init = async (pusher) => {
  if (!pusher) return;
  if (!window.raiPosAsync) return;
  if (!window.raiPosAsync.raiPosVersion) return; // ensure we are on newest pos version

  console.log("Initializing POS pusher channel");

  const channel = await presenceChannel(pusher);
  const posName = (await raiPosAPI.computerName())
    .replace(/\s+/g, "")
    .toLowerCase();

  channel &&
    posName &&
    channel.bind(`client-proxy:pos-${posName}-message`, async (data) => {
      console.log("handling message from tablet", data);
      const parsed = typeof data === "string" ? JSON.parse(data) : data;
      const { action, from, actionId } = parsed;

      // Call the specified action
      const { result, error } = await handleAction(parsed);

      // Send a client event for the tablet to consume with the response
      channel.trigger(`client-proxy:tablet-${from}-message`, {
        action,
        from: posName,
        actionId,
        result,
        error,
      });
      return result;
    });
};

// Calls handler in ACTIONS
// Handlers should be in the format `action/1`, where the argument is `payload`
const handleAction = ({ action, payload }) => {
  if (!ACTIONS[action]) {
    throw new Error(`${action} is not an implemented action.`);
  }
  return ACTIONS[action](payload);
};

// Registry of actions that can be handled from cross-device async RPC
const ACTIONS = {
  // Calls the printTestPage() API method and returns the result
  async printTestPage(_payload) {
    let result, error;
    try {
      result = await raiPosAPI.printTestPage();
    } catch (e) {
      error = e.message;
    }

    return {
      result,
      error,
    };
  },
  // Calls the checkIn() API method and returns the result or an error
  // TODO: This error could be more descriptive or helpful
  async checkIn({ buy, customer, loyalty }) {
    if (!buy) return { error: "No buy was received with payload" };
    if (!customer) return { error: "No customer was received with payload" };

    try {
      const result = await raiPosAPI.checkIn(buy, customer, loyalty);
      if (!result)
        return {
          result,
          error: "Error checking in buy",
        };
      return {
        result,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  },
  async printReceipt({ buyJSON, customerJSON }) {
    if (!buyJSON) return { error: "No buy was received with payload" };
    if (!customerJSON)
      return { error: "No customer was received with payload" };

    try {
      const result = await raiPosAPI.printReceipt(buyJSON, customerJSON);
      if (!result) {
        return {
          result,
          error: "Error printing buy slips",
        };
      }
      return {
        result,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  },
};
